@import "../common";

$color-link: $color-main;

.nav-link {
  cursor: pointer;

  &.active {
    color: $color-link !important;
    border-bottom: 1px solid $color-link;
  }
}