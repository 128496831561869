@import "../common";

$color: $color-main;
$color-link: #FFFFFF;

.social-button-bar {

  // don't display the social-button-bar on "small" devices
  @include media-breakpoint-down(sm) {
    display: none;
  }

  position: fixed;
  top: 30%;

  a {
    color: $color-main !important;
    text-decoration: none !important;
  }
  a:hover {
    color: $color-main !important;
  }

  ul {
    padding: 0;
    -webkit-transform: translate(-270px, 0);
    -moz-transform: translate(-270px, 0);
    -ms-transform: translate(-270px, 0);
    -o-transform: translate(-270px, 0);
    transform: translate(-270px, 0);

    li {
      display: block;
      margin: 5px;
      background: rgba(0, 0, 0, 0.36);
      width: 300px;
      text-align: right;
      padding: 10px;
      -webkit-border-radius: 0 30px 30px 0;
      -moz-border-radius: 0 30px 30px 0;
      border-radius: 0 30px 30px 0;
      -webkit-transition: all 1s;
      -moz-transition: all 1s;
      -ms-transition: all 1s;
      -o-transition: all 1s;
      transition: all 1s;

      .social-button {
        margin-left: 20px;
        color: #2c2c2c;
        background: #FFFFFF;
        padding: 10px;
        -webkit-border-radius: 50%;
        -moz-border-radius: 50%;
        border-radius: 50%;
        width: 40px;
        height: 40px;
        font-size: 20px;
        @include rotate(0deg);
      }
    }

    li:hover {
      -webkit-transform: translate(150px, 0);
      -moz-transform: translate(150px, 0);
      -ms-transform: translate(150px, 0);
      -o-transform: translate(150px, 0);
      transform: translate(150px, 0);
      background: rgba(255, 255, 255, 0.75);

      a {
        color: $color-main;
      }

      .social-button {
        color: #FFFFFF;
        background: $color-main;
        @include rotate(360deg);
        -webkit-transition: all 1s;
        -moz-transition: all 1s;
        -ms-transition: all 1s;
        -o-transition: all 1s;
        transition: all 1s;
      }
    }

  }
}