@import "../common";

.discography {
  max-height: 80%;
  overflow-y: scroll;
  color: $color-text;
}

.discography-menu {
  //margin-top: 2rem;
  background-color: $color-content-bg;

  .discography-menu-item {
    padding: 0.5rem;

    img {
      @include border;
      opacity: 0.75;
      filter: alpha(opacity=75);

      &:hover, &.active {
        opacity: 1.0;
        filter: alpha(opacity=100);
      }
    }
  }
}

.discography-detail {
  //margin-top: 2rem;
  background-color: $color-content-bg;
  padding: 2rem;
}