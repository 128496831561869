@import "../common";

.concert-calendar {

  // restrict the height on small devices
  @include media-breakpoint-down(sm) {
    max-height: 400px;
    padding: 0;
    font-size: 14px;
  }

  max-height: 720px;
  background-color: $color-content-bg;
  overflow-y: scroll;
  padding: 1.5rem;

  thead {
    background-color: $color-content-bg-hover;

    tr {
      height: 1.5rem;

      th {
        border-color: $color-border;
        font-weight: bold;
      }
    }
  }

  tbody {
    tr {
      &.upcomingConcertRow {
        color: rgba(255, 255, 255, 1);
      }

      &.pastConcertRow {
        color: rgba(255, 255, 255, 0.20);
      }

      td {
        border-color: $color-border;

        &:nth-child(1) {
          text-align: right;
        }
      }
    }
  }

  .concert-calendar-table tbody tr:hover td {
    background-color: rgba(255, 255, 255, 0.50);
    color: rgba(0, 0, 0, 0.80);
  }

  .concert-link {
    color: $color-main !important;
    font-weight: bold;
  }
}

