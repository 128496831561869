@import "../common";
@import url('https://fonts.googleapis.com/css2?family=Castoro&display=swap');

$color-headline: $color-main;

$color-text: #FFFFFF;
$color-background: #000000;

$color-link: rgba(255, 255, 255, 0.5);
$color-link-hover: rgba(255, 255, 255, 0.75);

.h-100 {
  min-height: 100vh;
}

body, html {
  background-color: $color-background;
  height: 100% !important;
  font-family: 'Castoro', serif;
}

h1 {
  text-align: center;
  color: $color-headline !important;
  margin-top: 5rem !important;
  margin-bottom: 1rem !important;
}

h2 {
  text-align: center;
  color: $color-text !important;
}

.main-logo {

  // "small" devices only
  @include media-breakpoint-down(sm) {
    margin-top: 3rem;
  }

  background-color: rgba(0, 0, 0, 0.75);
  padding-top: 3rem;
  padding-bottom: 3rem;
  text-align: center;
  color: $color-headline;
  border-top: 0.05rem solid;
  border-bottom: 0.05rem solid;

  .main-logo-header {
    font-size: 3rem;
  }
  .main-logo-tagline {
    font-size: 1.25rem;
    color: $color-link;
  }
  .main-logo-social-buttons {
    display: none;

    // display only on "small" devices
    @include media-breakpoint-down(sm) {
      display: block;
      margin-top: 3rem;
    }

    // TODO: code-duplication with socialButtonBar.scss
    .social-button {
      margin-left: 10px;
      margin-right: 10px;
      color: #000000;
      background: #FFFFFF;
      padding: 10px;
      -webkit-border-radius: 50%;
      -moz-border-radius: 50%;
      border-radius: 50%;
      width: 40px;
      height: 40px;
      font-size: 20px;
      @include rotate(0deg);
    }
  }

}


a {
  color: $color-link !important;

  &:hover {
    color: $color-link-hover !important;
  }
}

.navbar {
  background-color: rgba(10, 10, 10, 0.9) !important;
  color: $color-link !important;
  text-align: center !important;
}

#logo {
  margin-right: 2em;
}

#section-main {
  @include media-breakpoint-down(sm) {
    @include full-background-image("../static/img/background/bg01_400x800.jpg");
  }
  @include media-breakpoint-up(sm) {
    @include full-background-image("../static/img/background/bg01_1920x1080.jpg");
  }
}

#section-tour {
  @include full-background-image("../static/img/background/bg02_1920x1080.jpg");
  padding-bottom: 3.5rem;
}

#section-music {
  @include full-background-image("../static/img/background/bg01_1920x1080.jpg");
  padding-bottom: 3.5rem;
}

#section-video {
  @include full-background-image("../static/img/background/bg02_1920x1080.jpg");
}

#section-shop {
  @include full-background-image("../static/img/background/bg03_1920x1080.jpg");
  height: 100%;

  h1 {
    background-color: rgba(255, 255, 255, 0.85);
  }
}

#section-contact {
  @include full-background-image("../static/img/background/bg02_1920x1080.jpg");
  padding-bottom: 3.5rem;
}

.video-content {
  @include media-breakpoint-down(lg) {
    display: none;
  }
  @include media-breakpoint-up(lg) {
    display: block;
  }
}

.video-content-lg {
  @include media-breakpoint-down(lg) {
    display: block;
  }
  @include media-breakpoint-up(lg) {
    display: none;
  }

  .video {
    margin-top: 3rem;
  }
}

.shop {
  background-color: rgba(0, 0, 0, 0.90);
  padding-top: 4rem;
  padding-bottom: 4rem;
  margin-bottom: 2rem;
  text-align: center;
  color: $color-headline;
  border-top: 0.05rem solid;
  border-bottom: 0.05rem solid;

  .shop-images {
    display: inline-block;
    vertical-align: top;

    .shirts {
      @include media-breakpoint-down(sm) {
        width: 200px;
      }
    }

    .items {
      @include media-breakpoint-down(lg) {
        display: none;
      }
      @include media-breakpoint-up(lg) {
        display: inherit;
      }
    }
  }

  .shop-description {
    display: inline-block;
  }

  .shop-description-header {
    font-size: 3rem;
    padding-top: 6rem;
    padding-bottom: 2rem;

    @include media-breakpoint-down(lg) {
      padding-top: 2rem;
    }
  }

  .shop-description-tagline {
    display: inline-block;
    font-size: 1.25rem;
    color: $white;
  }

  .shop-description-radiant {
    margin-left: 1rem;
    font-size: 1.25rem;
  }

  .shop-description-link {
    font-size: 2rem;
    padding-top: 2rem;

    a {
      color: $color-main !important;
    }
  }

}