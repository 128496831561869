@import "../common";

$color: $color-main;

.headline-container {
  text-align: center;
}

.headline {
  color: $color;
  border-color: $color;
  text-transform: uppercase;
  font-weight: 300;
  margin: 0;
  padding: 5px 10px;
  border-bottom: 3px solid;
  display: inline-block;
  position: relative;

  &:after {
    content: '';
    height: 0;
    width: 0;
    position: absolute;
    bottom: -23px;
    left: 50%;
    margin-left: -10px;
    background: none;
    border: 10px solid transparent;
    border-top-color: $color;
  }
}