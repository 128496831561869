@import "../common";

#contact-info {
  background-color: $color-content-bg;
  margin: 3rem 0 3rem 0;
  padding: 1.5rem;

  table {
    color: $color-text !important;

    tr td {
      border: none;
    }

    td.contact-info-type {
      text-align: right;
      color: $color-main;
    }
  }

  table.imprint {
    line-height: 1.75;
  }
}