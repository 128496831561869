@import "~bootstrap/scss/_functions.scss";
@import "~bootstrap/scss/_variables.scss";
@import "~bootstrap/scss/mixins/_breakpoints.scss";

//$color-main: #f0efeb;
$color-main: #357aa3;
$color-secondary: #FFFFFF;
$color-shadow: #000000;
$color-text: #FFFFFF;
$color-border: rgba(255, 255, 255, 0.25);
$color-background: #000000;
$color-content-bg: rgba(0, 0, 0, 0.88);
$color-content-bg-hover: rgba(255, 255, 255, 0.88);

@mixin border() {
  border: 1px solid $color-border;
}

@mixin background-clip() {
  background-clip: border-box;
  -moz-background-clip: border;
  -webkit-background-clip: border-box;
}

@mixin border-radius($radius) {
  border-radius: $radius;
  -moz-border-radius: $radius;
  -webkit-border-radius: $radius;
}

@mixin box-shadow($h-offset, $v-offset, $blur, $spread, $color) {
  box-shadow: $h-offset $v-offset $blur, $spread, $color;
  -moz-box-shadow: $h-offset $v-offset $blur, $spread, $color;
  -webkit-box-shadow: $h-offset $v-offset $blur, $spread, $color;
}

@mixin full-background-image($path) {
  background: url($path) no-repeat center;
  background-size: cover;
};

@mixin rotate($degree) {
  -webkit-transform: rotate($degree);
  -moz-transform: rotate($degree);
  -ms-transform: rotate($degree);
  -o-transform: rotate($degree);
  transform: rotate($degree);
}

@mixin transition($property, $duration, $timing-function) {
  transition: $property $duration $timing-function;
  -moz-transition: $property $duration $timing-function;
  -o-transition: $property $duration $timing-function;
  -webkit-transition: $property $duration $timing-function;
}